import Service from "./Service";

class DevService extends Service {
  constructor() {
    super();
  }
  // cmd
  async checkServerStatus(collegData) {
    return this.callApiPostForEdr(`/checkServerStatus`, {}, collegData);
  }
  async excuteCmd(postData, collegData) {
    return this.callApiPostForEdr(`/excuteCmd`, postData, collegData);
  }
  async cmdServiceStop(postData, collegData) {
    return this.callApiPostForEdr(`/cmdServiceStop`, postData, collegData);
  }
  async cmdServiceStart(postData, collegData) {
    return this.callApiPostForEdr(`/cmdServiceStart`, postData, collegData);
  }
  async cmdServiceRestart(postData, collegData) {
    return this.callApiPostForEdr(`/cmdServiceRestart`, postData, collegData);
  }
  async cmdDeployEdrApp(postData, collegData) {
    return this.callApiPostForEdr(`/cmdDeployEdrApp`, postData, collegData);
  }
  async cmdDeployEdrPhpApp(postData, collegData) {
    return this.callApiPostForEdr(`/cmdDeployEdrPhpApp`, postData, collegData);
  }
  async cmdUpdatePermissionApi(collegData) {
    return this.callApiPostForEdr(`/cmdUpdatePermissionApi`, {}, collegData);
  }
  async broadcastReloadSocketRealtime(postData, collegData) {
    return this.callApiPostForEdr(
      `/broadcastReloadSocketRealtime`,
      postData,
      collegData
    );
  }
  async educationCalendarAddApi(postData, collegData) {
    return this.callApiPostForEdr(
      `/educationCalendarAddApi`,
      postData,
      collegData
    );
  }
  async devMigrateEdrData(collegData) {
    return this.callApiPostForEdr(`/devMigrateEdrData`, {}, collegData);
  }
  async devRenewSSLProcess(collegData) {
    return this.callApiPostForEdr(`/devRenewSSL`, {}, collegData);
  }
  async sendLineNotifyToEdr(postData, collegData) {
    return this.callApiPostForEdr(`/sendLineNotifyApi`, postData, collegData);
  }
  async uploadEdrWarFile(postData) {
    return this.callApiMultipart(`/uploadEdrWarFile`, postData);
  }
}
export default DevService;
